$primaryFontFamily: 'Redaction', 'Times New Roman', 'Liberation Serif', serif;
$secondaryFontFamily: 'Redaction Secondary', 'Times New Roman', 'Liberation Serif', serif;

$primaryBrandingColor: #cccccc;
$ctaColor: #222222;

$linkColor: $primaryBrandingColor;

$primaryBackgroundColor: #dddddd;

$primaryColor: rgba(#333333, 0.8);
$secondaryColor: #666666;

$accentColor: #00ff19;
