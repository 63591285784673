.btn {
  position: relative;
  box-sizing: border-box;
  height: auto;
  line-height: 1em;
  padding: 0.65em 1em 0.5em 1em;
  display: inline-block;
  border-radius: 4px;
  z-index: 0;
  cursor: pointer;
  text-decoration: none !important;
  background-color: transparent;
  user-select: none;
  font-size: 20px;
  margin-right: 0.125em;
  margin-bottom: 0.125em;
  margin-top: 0.125em;
  color: $primaryColor;
  border-radius: 170px;
  position: relative;
  z-index: 0;
  letter-spacing: 0 !important;
  vertical-align: middle;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.42);
    filter: blur(3px);
    border-radius: 70px;
  }
  &:hover {
    color: $accentColor;
    &:before {
      background: rgba(255, 255, 255, 1);
      filter: blur(5px);
    }
  }
  &.big {
    font-size: 30px;
    &:hover {
      &:before {
        filter: blur(7px);
      }
    }
  }
  > time {
    font-size: 20px;
    display: block;
    margin-top: -0.25em;
  }
  &.accent {
    text-transform: uppercase;
  }
  &[data-text] {
    &:after {
      content: attr(data-text);
      position: absolute;
      display: block;
      padding: 0.65em 1em 0.5em 1em;
      bottom: 0;
      opacity: 0;
      color: $accentColor;
      font-family: $secondaryFontFamily;
      left: -2%;
      width: 104%;
      text-align: center !important;
      box-sizing: border-box;
      // will-change: opacity;
      // transition: opacity 1s ease;
    }
    &:hover {
      color: transparent;
      time {
        color: $accentColor;
      }
      &:after {
        opacity: 1;
      }
    }
  }
  &[disabled] {
    pointer-events: none !important;
    opacity: 0.8;
  }
}
@media screen and (max-width: 1024px) {
  .btn.big {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .btn {
    font-size: 18px !important;
  }
}
