@import 'vars';

.text-content {
  font-size: 1.25rem; /* 20/16 */
  font-weight: 400;
  line-height: 1.25em;
  &.center {
    text-align: center;
  }
  .intro {
    font-size: 1.75em;
    line-height: 1.125em;
    max-width: 80vw;
    margin: 1em auto;
    letter-spacing: -0.03em;
  }
  .date {
    text-align: center;
  }
  h1 {
    font-size: 3em; /* 64/20 */
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    em {
      font-style: normal;
      display: block;
      text-transform: none;
    }
  }

  h2 {
    font-size: 1.5em; /* 48/20 */
    font-weight: 400;
  }

  h3 {
    font-size: 1.5em; /* 39/20 */
    font-weight: 400;
  }

  h4 {
    font-size: 1.5em; /* 31/20 */
    font-weight: 400;
  }

  h5 {
    font-size: 1.25em; /* 25/20 */
    font-weight: 400;
  }

  p {
    margin: 1.5em 0;
    line-height: 1.25em;
  }

  img {
    width: 100%;
    height: auto;
  }
  a {
    color: inherit;
    text-decoration-color: $accentColor;
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  p.note {
    font-size: 0.875rem !important;
    opacity: 0.6;
  }
  h1,
  h2,
  h3,
  h4 {
    margin: 1em 0 0 0;
    line-height: 1.125em;
  }

  ol {
    margin: 1.5em 0;
    counter-reset: item;
    padding-left: 2em;
  }
  ol li {
    margin: 0.5em 0;
    position: relative;
    padding-left: 2em;
  }
  ul {
    padding-left: 2em;
    list-style: disc;
    margin: 1.5em 0;
  }
  ul li {
    margin: 0.5em 0;
    position: relative;
    padding-left: 0.25em;
  }
  dl {
    margin: 1.5em 0;
  }
  dl dt {
    float: left;
    clear: left;
    margin: 0.25em 0;
  }
  dl dt:after {
    content: ':';
    padding-right: 0.5em;
  }
  dl dd {
    clear: right;
    float: left;
    padding-left: 0.5em;
    margin: 0.25em 0;
  }

  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  img {
    border-radius: 30px;
  }
  blockquote {
    text-align: center;
    max-width: 1000px;
    margin: 8em auto 0 auto;
    p {
      font-size: 3.75rem;
      letter-spacing: -0.003em;
      line-height: 1.075em;
      &:first-of-type:before {
        content: '»';
      }
      &:last-of-type:after {
        content: '«';
      }
    }
    + p {
      text-align: center;
      font-size: 30px;
      margin-top: -1.5em;
      margin-bottom: 3em;
      margin-bottom: 8rem;
      &:before {
        content: '— ';
      }
    }
  }
}

.img-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 0px 0px 15px 11px rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    pointer-events: none;
  }
  img {
    border-radius: 20px;
    width: 100%;
  }
}

.text-content {
  .img-wrapper:before,
  .img-wrapper img {
    border-radius: 30px;
  }
}

@media (max-width: 1440px) {
  .text-content {
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 1.75em;
    }
    h3 {
      font-size: 1.5em;
    }
    h4 {
      font-size: 1.25em;
    }
    h5 {
      font-size: 1.125em;
    }
  }
}

@media (max-width: 1024px) {
  .text-content {
    font-size: 1.125rem; /* 18/16 */
    blockquote p {
      font-size: 2em;
    }
    h1 {
      margin-top: 0;
    }
  }
}

.text-content .center {
  text-align: center;
}

.alternating-wrap {
  margin: 8em auto;
  .alternating {
    display: flex;
    align-items: flex-start;
    gap: 3em;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    .img-wrap {
      flex: 0 0 40%;
      margin-bottom: 5em;
      .img-wrapper {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .caption {
        max-width: 500px;
        line-height: 1.25em;
        margin: 1em 0;
        font-size: 1rem;
      }
    }
    .text-content {
      flex: 1 1 auto;
    }
  }
}
.news-navigator {
  margin-top: 8em;
  text-align: center;
}

.p2 {
  font-size: 30px;
}
@media screen and (max-width: 1024px) {
  .intro {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .text-content {
    font-size: 1.125rem; /* 18/16 */
    h1 {
      font-size: 32px;
    }
    .intro {
      font-size: 1.5em;
      p {
        line-height: 1.25em;
        font-size: 25px;
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .text-content .intro {
    max-width: 100%;
  }
}
