@media screen {
  .qty {
    display: flex;
    gap: 0.25em;
    justify-content: center;
    input {
      text-align: center;
      background-color: transparent;
      max-width: 3em;
    }
  }
  dl {
    margin: 4em auto !important;
    width: 100%;
    .prop-wrap {
      display: flex;
      line-height: 1.125em;
      margin: 0.05em 0;
      gap: 1em;
      font-size: 1.75em;
      dt,
      dd {
        flex: 1 1 0px;
      }
      dt {
        text-align: end;
        &:after {
          content: ':';
        }
      }
    }
  }
}
