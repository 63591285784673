@font-face {
  font-family: 'Redaction';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/fonts/Redaction-Regular.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Redaction';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/fonts/Redaction-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Redaction Secondary';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/fonts/Redaction_35-Regular.woff2') format('woff2');
  font-display: swap;
}
