@import './vars';

@media screen {
  @import 'reset';
  @import 'fonts';

  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  .wrap {
    width: 97%;
    // max-width: 1280px;
    margin: 0 auto;
    &.content-wrap {
      max-width: 900px;
    }
    @media screen and (min-width: 1025px) {
      &.dynamic {
        max-width: 75%;
      }
    }
  }

  body {
    min-height: 100%;
    max-width: 100%;
    font-size: 100%; // 16px
    font-weight: 400;
    background-color: $primaryBackgroundColor;
    font-family: $primaryFontFamily;
    color: $primaryColor;
    background-color: $primaryBackgroundColor;
    overflow-x: hidden;

    &:after {
      content: '';
      pointer-events: none;
      position: fixed;
      z-index: 30;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0px 0px 20px 11px #ffffff;
      background: url('/images/fog.png') center center no-repeat;
      background-size: cover;
      opacity: 0.5;
    }
  }

  .pageHeader {
    padding: 8px 0;
    .wrap {
      display: flex;
      h1 {
        flex: 1 1 auto;
      }
      .wallet {
        display: flex;
        align-items: center;
        gap: 1em;
      }
    }
  }

  @import 'text-content';
  @import 'btn';
  img {
    max-width: 100%;
    vertical-align: middle;
  }

  .hidden {
    display: none !important;
  }
}
